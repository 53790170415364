import Head from "next/head";

export default function Home({meta}) {
    return <>
        <Head>
            {meta && <meta name={meta.name} content={meta.value}/>}
        </Head>
        ok
    </>
}

export async function getServerSideProps(context) {
   /*
    * This file isn't used, all requests are
    */
    return {
        props: {}
    }
}